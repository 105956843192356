<template>
  <div>
    <FeathersServiceTable
      service="cases"
      :params="casesParams"
      :headers="caseHeaders"
      :loading="jobLoadPending || caseLoadPending"
      class="elevation-1"
      ref="table"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Cases</v-toolbar-title>
          <v-spacer></v-spacer>
          <DialogForm title="New Case" icon="add" max-width="600px" :submit="addCase(caseName)">
            <template #default>
              <v-text-field
                label="Name"
                v-model="caseName"
                counter="64"
                :rules="[
                  $utils.validation.required,
                  $utils.validation.counter(64),
                  $utils.validation.specialCharacters,
                ]"
                :loading="caseLoadPending"
                maxlength="64"
              ></v-text-field>
            </template>
          </DialogForm>
        </v-toolbar>
      </template>

      <template v-for="calc in calculations" #[`header.${calc.name}`]="{ header }">
        <v-tooltip top :key="calc.type">
          <template #activator="{ on }">
            <span v-on="on">
              <a :href="header.wikiUrl" class="table-header-link"> {{ header.text }}</a>
            </span>
          </template>
          <span>
            {{ header.tooltip }}
          </span>
        </v-tooltip>
      </template>

      <template #item.name="{ item }" :jobs="loadCaseJobs(item.id)">
        <router-link :to="gotoCase(item.scenario_id, item.id)">
          {{ item.name }}
        </router-link>
      </template>
      <template #item.updated="{ item }">
        <span :title="new Date(item.updated)">{{ formatDate(item.updated) }}</span>
      </template>

      <template #item.notes="{ item }">
        <span>
          <pre class="text-body-2">{{ formatNotes(item.notes) }}</pre>
        </span>
      </template>
      <!-- this is the dumbest hack i've done
      https://vuejs.org/v2/guide/syntax.html#Dynamic-Arguments
      should provide some context -->
      <template v-for="calc in calculations" #[`item.${calc.name}`]="{ item }">
        <JobIndicator
          :key="calc.name"
          :case="item"
          :job="getJobForCase(item.id, calc.type)"
          :jobType="calc.type"
        ></JobIndicator>
      </template>
      <template #item.actions="{ item }">
        <DialogForm title="Edit Case" icon="edit" :submit="updateCase(item)" @click.stop>
          <template #default>
            <v-text-field
              label="Name"
              v-model="item.name"
              counter="64"
              :rules="[$utils.validation.required, $utils.validation.counter(64)]"
              maxlength="64"
            ></v-text-field>
            <v-textarea filled label="Notes" v-model="item.notes"></v-textarea>
          </template>
        </DialogForm>

        <v-btn icon @click.stop="copyCase(item.id)()">
          <v-icon>content_copy</v-icon>
        </v-btn>

        <v-btn icon @click.stop="removeCase(item.id)">
          <v-icon>delete</v-icon>
        </v-btn>

        <MenuMore offset-y left>
          <v-list dense>
            <DialogForm title="Move to scenario..." :submit="moveCase(item)" @click.stop>
              <template #activator="{ on: dialogOn }">
                <v-list-item v-on="dialogOn">
                  <v-list-item-icon>
                    <v-icon>folder_open</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Move to </v-list-item-title>
                </v-list-item>
              </template>

              <template #default>
                <SelectScenario
                  v-model="moveScenarioForm"
                  flat
                  hide-details
                  filled
                ></SelectScenario>
              </template>
            </DialogForm>
          </v-list>
          <v-divider />

          <v-list dense>
            <DialogForm
              title="Copy Case"
              icon="file_copy"
              maxWidth="600"
              :submit="remodelCase(item.id)"
              @click.stop
              @open="fillCopyForm(item)"
            >
              <template #activator="{ on: dialogOn }">
                <v-list-item v-on="dialogOn">
                  <v-list-item-icon>
                    <v-icon>file_copy</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Remodel </v-list-item-title>
                </v-list-item>
              </template>

              <template #default>
                <v-text-field
                  label="Case Name"
                  v-model="copyForm.name"
                  counter="64"
                  :rules="[$utils.validation.required, $utils.validation.counter(64)]"
                  maxlength="64"
                ></v-text-field>
                <v-switch
                  v-model="copyForm.disableStorage"
                  label="Disable Storage Expansion"
                  dense
                />
                <v-switch
                  v-model="copyForm.createDelta"
                  label="Create Delta against original case"
                  dense
                />
                <v-text-field
                  v-if="copyForm.createDelta"
                  v-model="copyForm.deltaName"
                  :rules="[$utils.validation.required]"
                  label="Delta Name"
                  class="pl-6 pr-4"
                />
                <v-switch
                  v-model="copyForm.keepResults"
                  label="Keep previous results"
                  hint="Keep Alternative Analysis and Production Cost results when updating case"
                  persistent-hint
                  dense
                />
              </template>
            </DialogForm>

            <DialogForm
              title="Share Case"
              icon="share"
              :submit="shareCase(item, shareUserEmail)"
              submit-text="Share"
              @click.stop
              @open="shareUserEmail = ''"
            >
              <template #activator="{ on: dialogOn }">
                <v-list-item v-on="dialogOn">
                  <v-list-item-icon>
                    <v-icon>share</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Share </v-list-item-title>
                </v-list-item>
              </template>
              <template #default>
                <v-text-field
                  v-model="shareUserEmail"
                  label="Email"
                  placeholder="Enter email..."
                ></v-text-field>
              </template>
            </DialogForm>

            <v-list-item @click.stop="downloadCase(item.id)">
              <v-list-item-icon>
                <v-icon>get_app</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Download </v-list-item-title>
            </v-list-item>
          </v-list>
        </MenuMore>
      </template>
    </FeathersServiceTable>
    <v-snackbar v-model="snackbar" left color="success">
      {{ snackbarText }}
      <template #action="{}">
        <v-btn text @click="snackbar = false" icon>
          <v-icon>cancel</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import getUrl from "@/plugins/getUrl";

export default {
  name: "CaseTable",
  components: {
    JobIndicator: () => import("@/components/JobIndicator"),
    DialogForm: () => import("@/components/DialogForm"),
    FeathersServiceTable: () => import("@/components/FeathersServiceTable"),
    MenuMore: () => import("@/components/MenuMore"),
    SelectScenario: () => import("@/components/SelectScenario"),
  },
  mixins: [],
  props: {
    scenarioId: {
      type: Number,
      required: true,
    },
    scenarioName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copyDialog: false,
      copyForm: {
        id: "",
        name: "",
        disableStorage: false,
        createDelta: false,
        deltaName: "",
        keepResults: false,
      },
      moveScenarioForm: this.scenarioId,
      shareUserEmail: "",
      caseName: "",
      calculations: [
        {
          name: "ALTERNATIVE_ANALYSIS",
          type: 1,
        },
        {
          name: "PRODUCTION_COST",
          type: 2,
        },
        {
          name: "STACKED_SERVICES_EMULATOR",
          type: 5,
        },
      ],
      caseHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Last Saved",
          value: "updated",
        },
        {
          text: "Notes",
          value: "notes",
          width: "300px",
        },
        {
          text: "AA",
          value: "ALTERNATIVE_ANALYSIS",
          align: "center",
          sortable: false,
          width: 1,
          tooltip: "Alternative Analysis",
          wikiUrl: getUrl("/wiki/Algorithm%20Modules/Alternative%20Analysis%20Module.html"),
        },
        {
          text: "PC",
          value: "PRODUCTION_COST",
          align: "center",
          sortable: false,
          width: 1,
          tooltip: "Production Cost",
          wikiUrl: getUrl("/wiki/Algorithm%20Modules/Production%20Cost%20Module.html"),
        },
        {
          text: "SSE",
          value: "STACKED_SERVICES_EMULATOR",
          align: "center",
          sortable: false,
          width: 1,
          tooltip: "Stacked Services Emulator",
          wikiUrl: getUrl("/wiki/Algorithm%20Modules/Stacked%20Services%20Emulator%20Module.html"),
        },
        {
          text: "",
          align: "right",
          value: "actions",
          sortable: false,
        },
      ],
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapState({
      getCurrentUser: (state) => (state.auth.user ? state.auth.user.email : null),
      getCurrentOrganization: (state) => (state.auth.user ? state.auth.user.organization : null),
    }),
    ...mapGetters({
      getCases: "cases/find",
      getJobs: "jobs/find",
      caseLoadPending: "cases/loadPending",
      jobLoadPending: "jobs/loadPending",
    }),
    casesParams() {
      return {
        query: { scenario_id: this.scenarioId },
      };
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      loadCase: "cases/get",
      loadScenario: "scenarios/get",
      findScenarios: "scenarios/find",
      createScenario: "scenarios/create",
      createCase: "cases/create",
      patchCase: "cases/patch",
      downloadCase: "cases/downloadOutputs",
      removeCase: "cases/confirmRemove",
      loadJobs: "jobs/find",
      createDelta: "deltas/create",
      findUser: "users/find",
    }),
    loadCaseJobs(caseId) {
      return this.loadJobs({ query: { case_id: caseId } });
    },
    getJobForCase(caseId, jobType) {
      const res = this.getJobs({ query: { case_id: caseId, type: jobType } }).data[0];
      return res;
    },
    dateWithBreak(date) {
      const datestamp = new Date(date);
      const day = datestamp.toLocaleDateString();
      const time = datestamp.toLocaleTimeString();
      return `${day} <wbr>${time}`;
    },
    formatDate(date) {
      const datestamp = new Date(date);

      return datestamp.toLocaleString(undefined, {
        dateStyle: "short",
        timeStyle: "short",
        hour12: false,
      });
    },
    formatNotes(note) {
      if (note != null && note.includes("Shared at:")) {
        const [datetime] = note.match(
          /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g
        );
        const parsedDate = this.formatDate(datetime);
        const parsedNote = note.replace(datetime, parsedDate);
        return parsedNote;
      }
      return note;
    },
    addCase(name) {
      return () => {
        console.debug("Create Case", name);
        return this.createCase({
          name,
          scenario_id: this.scenarioId,
        }).then((res) => {
          console.debug(res);
          this.caseName = "";
          this.$refs.table.findService();
        });
      };
    },
    updateCase(_case) {
      return () => {
        console.debug("Update Case", _case);
        const patchedCase = { name: _case.name, notes: _case.notes };
        return this.patchCase([_case.id, patchedCase]);
      };
    },
    moveCase(_case) {
      return () => {
        console.debug("Moved Case", _case);
        const patchedCase = { scenario_id: this.moveScenarioForm };
        return this.patchCase([_case.id, patchedCase]).then(() => {
          this.moveScenarioForm = this.scenarioId;
        });
      };
    },
    shareCase(_case, email) {
      return () => {
        console.debug(`Share case ${_case.id} with ${email}`);
        return this.createCase([{}, { query: { share: { caseId: _case.id, email } } }]).finally(
          () => {
            this.snackbar = true;
            this.snackbarText = `Case '${_case.name}' shared with ${email}, if they have an acoount`;
          }
        );
      };
    },
    copyCase(caseId, { nameSuffix = "Copy" } = {}) {
      return () =>
        this.loadCase(caseId)
          .then((_case) => {
            const newCase = _case.clone();
            delete newCase.id;
            delete newCase.created;
            delete newCase.updated;
            delete newCase.jobs;
            newCase.name += ` ${nameSuffix}`;
            console.debug("save", _case);
            return newCase.save();
          })
          .then((_case) => {
            this.loadScenario(_case.scenario_id);
            this.$refs.table.findService();
          });
    },
    remodelCase(caseId) {
      return () =>
        this.loadCase(caseId)
          .then((_case) => {
            console.debug(_case);
            const newCase = _case.clone();
            delete newCase.id;
            delete newCase.created;
            delete newCase.updated;
            delete newCase.jobs;

            if (newCase.name === this.copyForm.name) {
              newCase.name += " Copy";
            } else {
              newCase.name = this.copyForm.name;
            }
            if (this.copyForm.disableStorage) {
              newCase.input.storage = newCase.input.storage.map((v) => ({
                ...v,
                expansion_candidate: false,
                maximum_build: Array(v.maximum_build.length).fill(0),
              }));
              newCase.metadata = { bess_expansion: false };
              newCase.name += " No Storage";
            }
            if (this.copyForm.keepResults) {
              newCase.settings.ALTERNATIVE_ANALYSIS.saveOutputs = true;
              newCase.settings.PRODUCTION_COST.saveOutputs = true;
            }
            if (this.copyForm.createDelta) {
              newCase.metadata = { delta_created: true, ...newCase.metadata };
            }
            return newCase.save();
          })
          .then((_case) => {
            if (this.copyForm.createDelta) {
              this.createDelta({
                caseA_scenario: _case.scenario_id,
                caseA_id: this.copyForm.id,
                caseB_scenario: _case.scenario_id,
                caseB_id: _case.id,
                name: this.copyForm.deltaName,
                type: 3,
              });
            }
            this.loadScenario(_case.scenario_id);
          });
    },
    gotoCase(scenario, id) {
      return {
        name: "CaseInput",
        params: { scenario, id },
      };
    },
    fillCopyForm(item) {
      this.copyForm = {
        id: item.id,
        name: `${item.name} Copy`,
        disableStorage: false,
        createDelta: false,
        deltaName: "",
        keepResults: false,
      };
    },
  },
};
</script>

<style>
span.avoidWrap {
  display: inline-block;
}
.table-header-link {
  color: rgba(0, 0, 0, 0.6) !important;
  text-decoration: none;
}
.table-header-link:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

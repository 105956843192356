var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FeathersServiceTable',{ref:"table",staticClass:"elevation-1",attrs:{"service":"cases","params":_vm.casesParams,"headers":_vm.caseHeaders,"loading":_vm.jobLoadPending || _vm.caseLoadPending},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Cases")]),_c('v-spacer'),_c('DialogForm',{attrs:{"title":"New Case","icon":"add","max-width":"600px","submit":_vm.addCase(_vm.caseName)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name","counter":"64","rules":[
                _vm.$utils.validation.required,
                _vm.$utils.validation.counter(64),
                _vm.$utils.validation.specialCharacters ],"loading":_vm.caseLoadPending,"maxlength":"64"},model:{value:(_vm.caseName),callback:function ($$v) {_vm.caseName=$$v},expression:"caseName"}})]},proxy:true}])})],1)]},proxy:true},_vm._l((_vm.calculations),function(calc){return {key:("header." + (calc.name)),fn:function(ref){
              var header = ref.header;
return [_c('v-tooltip',{key:calc.type,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_c('a',{staticClass:"table-header-link",attrs:{"href":header.wikiUrl}},[_vm._v(" "+_vm._s(header.text))])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])])]}}}),{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.gotoCase(item.scenario_id, item.id)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.updated",fn:function(ref){
              var item = ref.item;
return [_c('span',{attrs:{"title":new Date(item.updated)}},[_vm._v(_vm._s(_vm.formatDate(item.updated)))])]}},{key:"item.notes",fn:function(ref){
              var item = ref.item;
return [_c('span',[_c('pre',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.formatNotes(item.notes)))])])]}},_vm._l((_vm.calculations),function(calc){return {key:("item." + (calc.name)),fn:function(ref){
              var item = ref.item;
return [_c('JobIndicator',{key:calc.name,attrs:{"case":item,"job":_vm.getJobForCase(item.id, calc.type),"jobType":calc.type}})]}}}),{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('DialogForm',{attrs:{"title":"Edit Case","icon":"edit","submit":_vm.updateCase(item)},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name","counter":"64","rules":[_vm.$utils.validation.required, _vm.$utils.validation.counter(64)],"maxlength":"64"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-textarea',{attrs:{"filled":"","label":"Notes"},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}})]},proxy:true}],null,true)}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.copyCase(item.id)()}}},[_c('v-icon',[_vm._v("content_copy")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeCase(item.id)}}},[_c('v-icon',[_vm._v("delete")])],1),_c('MenuMore',{attrs:{"offset-y":"","left":""}},[_c('v-list',{attrs:{"dense":""}},[_c('DialogForm',{attrs:{"title":"Move to scenario...","submit":_vm.moveCase(item)},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var dialogOn = ref.on;
return [_c('v-list-item',_vm._g({},dialogOn),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("folder_open")])],1),_c('v-list-item-title',[_vm._v(" Move to ")])],1)]}},{key:"default",fn:function(){return [_c('SelectScenario',{attrs:{"flat":"","hide-details":"","filled":""},model:{value:(_vm.moveScenarioForm),callback:function ($$v) {_vm.moveScenarioForm=$$v},expression:"moveScenarioForm"}})]},proxy:true}],null,true)})],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_c('DialogForm',{attrs:{"title":"Copy Case","icon":"file_copy","maxWidth":"600","submit":_vm.remodelCase(item.id)},on:{"click":function($event){$event.stopPropagation();},"open":function($event){return _vm.fillCopyForm(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var dialogOn = ref.on;
return [_c('v-list-item',_vm._g({},dialogOn),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("file_copy")])],1),_c('v-list-item-title',[_vm._v(" Remodel ")])],1)]}},{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Case Name","counter":"64","rules":[_vm.$utils.validation.required, _vm.$utils.validation.counter(64)],"maxlength":"64"},model:{value:(_vm.copyForm.name),callback:function ($$v) {_vm.$set(_vm.copyForm, "name", $$v)},expression:"copyForm.name"}}),_c('v-switch',{attrs:{"label":"Disable Storage Expansion","dense":""},model:{value:(_vm.copyForm.disableStorage),callback:function ($$v) {_vm.$set(_vm.copyForm, "disableStorage", $$v)},expression:"copyForm.disableStorage"}}),_c('v-switch',{attrs:{"label":"Create Delta against original case","dense":""},model:{value:(_vm.copyForm.createDelta),callback:function ($$v) {_vm.$set(_vm.copyForm, "createDelta", $$v)},expression:"copyForm.createDelta"}}),(_vm.copyForm.createDelta)?_c('v-text-field',{staticClass:"pl-6 pr-4",attrs:{"rules":[_vm.$utils.validation.required],"label":"Delta Name"},model:{value:(_vm.copyForm.deltaName),callback:function ($$v) {_vm.$set(_vm.copyForm, "deltaName", $$v)},expression:"copyForm.deltaName"}}):_vm._e(),_c('v-switch',{attrs:{"label":"Keep previous results","hint":"Keep Alternative Analysis and Production Cost results when updating case","persistent-hint":"","dense":""},model:{value:(_vm.copyForm.keepResults),callback:function ($$v) {_vm.$set(_vm.copyForm, "keepResults", $$v)},expression:"copyForm.keepResults"}})]},proxy:true}],null,true)}),_c('DialogForm',{attrs:{"title":"Share Case","icon":"share","submit":_vm.shareCase(item, _vm.shareUserEmail),"submit-text":"Share"},on:{"click":function($event){$event.stopPropagation();},"open":function($event){_vm.shareUserEmail = ''}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var dialogOn = ref.on;
return [_c('v-list-item',_vm._g({},dialogOn),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("share")])],1),_c('v-list-item-title',[_vm._v(" Share ")])],1)]}},{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Email","placeholder":"Enter email..."},model:{value:(_vm.shareUserEmail),callback:function ($$v) {_vm.shareUserEmail=$$v},expression:"shareUserEmail"}})]},proxy:true}],null,true)}),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.downloadCase(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("get_app")])],1),_c('v-list-item-title',[_vm._v(" Download ")])],1)],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"left":"","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){return [_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("cancel")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }